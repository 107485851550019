import { useTranslate } from "react-polyglot";
import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import YellowButton from "../../../SharedComponents/YellowButton.jsx";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import OrangeSecondButton from "../../../SharedComponents/OrangeSecondButton.jsx";
import RedButton from "../../../SharedComponents/RedButton.jsx";
import useCompanyPublicId from "../../../hooks/useCompanyPublicId";
import { usePayment } from "../../../Providers/Payment/PaymentProvider.jsx";

const useStyles = makeStyles(() => ({
  cardBtn: {
    margin: 8,
  },
}));

/* eslint-disable react/prop-types */
/**
 * @param {UserSubscribe} subscribe
 * @param {UserInfo} user
 * @param {Object} rest
 */
const EnableSubscribeButton = ({ subscribe, user, ...rest }) => {
  const t = useTranslate();
  const { enableSubscribe } = useBaseData();

  if (!user) {
    return null;
  }

  return (
    <OrangeSecondButton {...rest} onClick={() => enableSubscribe(subscribe.id)}>
      {t("enable")}
    </OrangeSecondButton>
  );
};

/* eslint-disable react/prop-types */
/**
 * @param {UserSubscribe} subscribe
 * @param {UserInfo} user
 * @param {Object} rest
 */
const StopSubscribeButton = ({ subscribe, user, ...rest }) => {
  const t = useTranslate();
  const { stopSubscribe } = useBaseData();

  if (!user) {
    return null;
  }

  return (
    <RedButton {...rest} onClick={() => stopSubscribe(subscribe.id)}>
      {t("stopSubscribe")}
    </RedButton>
  );
};

/**
 * @param {Offer} offer
 * @param {boolean} isOfferRepeat
 * @param {UserOffer} userOffer
 * @param {function} offerButtonHandler
 * @param {UserSubscribe} userSubscribe
 * @param {UserInfo} user
 * @return {*}
 * @constructor
 */
const BuyOrSubscribeButton = ({ offer, isOfferRepeat, userOffer, userSubscribe, user }) => {
  const classes = useStyles();
  const t = useTranslate();
  const history = useHistory();
  const { userInfo, setPreselectedOffer, setPreselectedSubject } = useBaseData();
  const { companyName } = useCompanyPublicId();

  useEffect(() => {}, [userInfo]);

  const { pay } = usePayment();

  /** */
  const payOrSet = (offer) => {
    console.log(user);
    if (!user) {
      setPreselectedOffer(offer.id);
      setPreselectedSubject(offer.subjectId);

      history.push(`${companyName ? "/" + companyName : ""}/auth`);
    } else {
      pay(offer.id);
    }
  };

  if (!offer.isSubscribe) {
    return (
      <YellowButton
        disabled={userOffer?.paid && !isOfferRepeat}
        className={classes.cardBtn}
        onClick={() => payOrSet(offer)}
      >
        <Typography>{userOffer && userOffer.status === "pending" ? t("proceed") : t("buyOffer")}</Typography>
      </YellowButton>
    );
  } else {
    if (userSubscribe && userSubscribe.status === "approved") {
      return <StopSubscribeButton className={classes.cardBtn} subscribe={userSubscribe} user={user} />;
    } else if (userSubscribe && userSubscribe.status === "stopped") {
      return <EnableSubscribeButton className={classes.cardBtn} subscribe={userSubscribe} user={user} />;
    } else {
      return (
        <YellowButton className={classes.cardBtn}>
          <Typography>{t("subscribeOffer")}</Typography>
        </YellowButton>
      );
    }
  }
};

export default BuyOrSubscribeButton;
