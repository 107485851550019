export default {
  title: {
    login: "%{school} - Login",
    registration: "%{school} - Registration",
    subjects: "%{school} - Select subject",
    subject: "%{school} - Subject",
    offer: "%{school} - Offer",
    home: "%{school} - Dashboard",
    dictionary: "%{school} - Dictionary",
    events: "%{school} - Events",
    news: "%{school} - News",
    profile: "%{school} - Profile",
    learn: "%{school} - Learning",
    pay: "%{school} - Subject purchasing",
    checkInSystem: "Are you already registered?",
    setPassword: "Enter password to login.",
    createAccount: "Create an account in the system.",
  },
  email: "Email",
  personalLink: "Personal link",
  login: "Login",
  name: "Name",
  phone: "Phone",
  aboutMe: "About me",
  registration: "Registration",
  password: "Password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  confirmNewPassword: "Confirm new password",
  forgotPassword: "Forgot your password?",
  passwordsMatch: "Passwords do not match",
  resetPassword: "Password reset successfully, check your email for details.",
  resetConfirm: "Do you want to reset your password?",
  enter: "Enter",
  studentEnter: "Student enter", //toDo
  check: "Check",
  emailIsNotRegistered: "Email is not Registered",
  logout: "Logout",
  cancel: "Cancel",
  stop: "Stop",
  no: "No",
  course: "Course",
  dictionary: "Dictionary",
  events: "Events",
  news: "News",
  words: "Words",
  wordsAreHere: "These words are here:",
  allWords: "All words",
  phrases: "Phrases",
  videos: "Videos",
  exercises: "Exercises",
  theory: "Theory",
  theoryAlt: "Конспект", //toDo
  theoryAdditionalFile: "Download additional file (%{filename})",
  topic: "Topic",
  lesson: "Lesson",
  profile: "Profile",
  orders: "Orders",
  payment: "Payment",
  leaderboard: "Leaderboard",
  time: "Time",
  points: "Points",
  courses: "My Courses",
  currentLang: {
    en: "English",
    ru: "Русский",
    fr: "Français",
    uk: "Українська",
  },
  loading: "Loading...",
  noTheory: "No theory available for this lesson",
  startTraining: "Start Training",
  missions: "Missions",
  branches: "Branches",
  easy: "Easy",
  expert: "Expert",
  wordToLearn: "Word to learn",
  iKnowWord: "I know this word",
  videoVocabulary: "Video Vocabulary",
  passed: "passed",
  unsupportedExercise: "Unsupported exercise (%{type})",
  changeCourse: "Change course",
  currentCourse: "Current course",
  allSubjects: "All subjects",
  subject: "Subject",
  group: "Group",
  start: "Start",
  chooseSubject: "Choose the subject you would like to study",
  hourValue: "%{value} h",
  completed: "Completed",
  notCompleted: "Not completed",
  paidBranch: "This branch is paid. Please purchase it to study.",
  buyBranch: "Buy branch for %{price} %{currency}",
  paidSubject: "This subject is paid. Please purchase it to study.",
  buySubject2: "Buy subject for %{price} %{currency}",
  decksItemNotOpen: "To open this lesson, you need to pass the previous lesson",
  decksItemNotPurchasedBranch: "The topic of this lesson is blocked",
  decksItemNotOpenLevel: "You have to pass a previous level in order to open this lesson",
  decksItemNotOpenBranch: "To open this lesson, you need to pass the previous topic",
  deckAccessDenied: "This lesson is not yet available to you",
  openOnDate: "will open on %{date}", // todo
  levelAccessIsDenied: "This level materials are not available to you",
  youAreDeactivated: "Your account has been deactivated, please contact your school",
  lockText: {
    p1: "Oops.. Unfortunately, you cannot access educational materials. School operations are temporarily suspended.",
    p2: "For detailed information and resolution of any issues, please contact school representatives.",
  },
  youAreDeactivatedShort: "Your account is deactivated",
  youAreHaveNotPaidOffers: "You are have unpaid offers",
  yourCertificates: "Your certificates",
  emptyDeck: "Deck is Empty",
  nextDeck: "Next lesson",
  next: "Go on",
  proceed: "Proceed",
  skip: "Skip",
  more: "More",
  exit: "Exit",
  details: "See details",
  profileEdit: "Edit user's profile",
  userInfo: "User info", //toDo
  of: "of",
  back: "Back",
  levelPackExist: "You already have this set of levels",
  awaitingPayment: "Awaiting payment",
  bought: "Bought",
  boughtUntilDate: "Bought until  %{endDate}",
  availableUntilDate: "Available until  %{endDate}",
  showMore: "show more",
  hide: "hide",
  communication: "Связь со школой", //toDo
  askQuestion: "Ask a question",
  noDescription: "Description missing",
  onePerMonth: "1 per Month",
  semiannually: "Semiannually",
  onceAYear: "Once a year",
  stopSubscribe: "Stop Subscribe",
  enable: "Enable Subscribe",
  expired: "Expired",
  buySubject: "Buy Subject",
  buyOffer: "Buy offer",
  subscribeOffer: "Subscribe offer",
  firstPayment: "First payment",
  accessDaysWarning: "%{smart_count} day left |||| %{smart_count} days left",
  accessDemoDaysWarning: "Demo: %{smart_count} day left |||| Demo: %{smart_count} days left",
  closeButton: "Close",
  Exercises: {
    tabTitle: "Exercises",
    skip: "Skip",
    check: "Check",
    typeAnswer: "Type your answer",
    next: "Go on",
    rightAnswerWas: "The correct answer was:",
    congratulation: "Congratulation!",
    congratText: "You have learned all exercises in this lesson!",
    wrongAnswer: "Your answer is wrong",
    answerSaved: "Answer saved", // todo
    commentToAnswer: "Comment to selected answer:", // todo
    appendix: {
      pdf: {
        tooltipButton: "Additional stuff", // todo
      },
    },
    type12: {
      title: "Find out more information", // todo
    },
    type11: {
      title: {
        select: "Choose the right answer",
        keyboard: "Write the right answer",
      },
    },
    type9: {
      title: {
        select: "Fill in the gaps by choosing words",
        keyboard: "Fill in the gaps",
      },
    },
    type8: {
      title: "Find matches",
    },
    type7: {
      title: {
        text: "Write your answer",
        audio: "Record your answer",
      },
      recordingIsNotSupported: "Audio recording is not supported",
      audioRecordingError: "The following getUserMedia error occurred: ",
      inputLabel: "Message",
      addPhoto: "Add photo/video (max 5)",
      cameraInitialization: "Initialization...",
      change: "Change",
      codeToApprove: "Password",
      homework: {
        teacherData: {
          audio: "Audio teacher's comment", // todo
          audioComment: "Voice teacher's comment", // todo
        },
      },
      addFiles: "Add files",
    },
    type6: {
      title: "Draw letter",
      checkButtonText: "Got it!",
    },
    type3: {
      title: "Drag&Drop the elements in order",
      hint: "Drag here items placed below",
    },
    type5: {
      title: "Select elements",
      hint: "Select the correct elements from the bottom of the screen (to choose - just click on them)",
    },
    type2: {
      title: "Choose the missing element",
    },
  },
  Events: {
    headNotice: "The event time is displayed according to your time zone", // todo
    title: "This week's events",
    startTime: "Start time",
    group: "Group",
    persons: "%{smart_count} person |||| %{smart_count} persons",
    leader: "Leader",
    tag: "Tag",
    joinedCheckbox: "Only Joined",
    joined: "Joined:",
    interested: "Registration",
    enter: "Enter", // todo
    limitPopupInfo: "You have reached the registration limit", //toDo
    eventHasStarted: "Подія вже почалася!", //toDo
    registrationNotice: "Ви успішно зареєструвалися! Кнопка входу буде активна у %{date_and_time}. Після цього часу ви не зможете скасувати реєстрацію на подію", //toDo
    registrationNoticeNoUrl: "Ви успішно зареєструвалися! Після %{date_and_time} ви не зможете скасувати реєстрацію на подію", //toDo
    cancelRegistration: "Ви дійсно хочете скасувати реєстрацію?", //toDo
    calendarLink: "Add Event to Google Calendar",
  },
  statistic: {
    title: "Statistics",
    points: "points",
    phrases: "phrases",
    missions: "missions",
    words: "words",
    time: "time",
  },
  status: {
    wait_review: "To be reviewed",
    on_review: "Reviewing",
    approved: "Approved",
    not_approved: "Not Approved",
    not_approved_allow_update: "Not Approved (allow edit)",
    ai_reviewed: "Checked with AI", // todo
  },
  homeworks: {
    tabName: "Homeworks",
    todo: "To do",
    done: "Done",
    topic: "Topic",
    lesson: "Lesson",
    onlyInMobileApp: "Available only in a mobile application",
    todoHomework: "PENDING HOMEWORK",
    postToDoButton: "Send",
    types: {
      homework_todo: "ToDo",
      exercise: "Exercise",
    },
  },
  exam: {
    popupTitle: "Lesson with exam",
    popupDescr1: "This is a lesson with an exam. First, study all the additional materials and only then proceed to the exercises.", //toDo
    popupDescr2: `As soon as you go to the "Exercises" tab, the countdown will begin.`, //toDo
    popupDescr3: 'The "Theory" and "Video" tabs do NOT start the exam countdown.', //toDo
    cancelButton: "Next time",
    positiveButton: "I understand",
    questions: "%{smart_count} question |||| %{smart_count} questions",
    oneAttempt: "One try per question",
    timeLimit: "Limited time: %{limit} min",
    noPause: "The exam cannot be paused",
    progress: "Question #%{current} of %{total}",
    closeButton: "Close",
    nextButton: "Next",
    finishedPopupTitle: "Final score",
    examPassed: "Exam passed",
    examNotPassed: "Exam not passed",
    waitHomeworks: "%{smart_count} question on review |||| %{smart_count} questions on review",
  },
  subjects: {
    unavailable: "The subject is currently unavailable",
  },
  orderStatus: {
    approved: "Approved",
    canceled: "Canceled",
    pending: "Pending",
  },
  notificationsTypes: {
    event: "event", //todo
    invoice: "invoice", //todo
    news: "news", //todo
    homework: "homework", //todo
    homework_todo: "homework_todo", //todo
    type: {
      exercise: "Homework", //todo
      homework_todo: "Homework To do", //todo
    },
  },
  notificationsAlert: {
    subscribe: "Turn on notifications",
    unsubscribe: "Turn off notifications",
    instructions: "Click the 🔒 lock to the left of the address bar and change the notification resolution as shown in the image or change your general browser settings.",
    permissionDefault: {
      title: "Subscribe to notifications 🔔",
      body: "Alerts on Homework status change, event start, news, new task to execute. No ads, just notifications about your learning!",
    },
    permissionDenied: {
      title: "Notifications are now turned off ❌",
      body: "You have previously turned off notifications. Without them, you can not know in time about a change in the status of the Homework, the beginning of the event. To turn on notifications, follow the instructions below:",
    },
    permissionGranted: {
      title: "Notifications are now enabled ✔",
      body: "Are you sure you want to opt out of notifications? Without them, you can't know in time about a change in the status of a Homework, the start of an event. To turn off notifications, follow the instructions below:",
    },
  },
  feedbackDialog: {
    sendFeedback: "Send feedback", // todo
    placeholder: "Tell us about your impressions", // todo
    defaultInstruction: "Use the slider to evaluate", // todo
    eventInstruction: "Rate how the event went", // todo
    submitError: "Please select a rating", // todo
    submitSuccess: "Thank you! Your feedback has been successfully sent", // todo
    wasNotPresent: "I did not attend the event", // todo
    dateOfEvent: "Date of the event:", // todo
  },
  congratulation: {
    mission: "Congratulations, %{name} mission is complete!",
  },
};
