import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentDialog from "../../SharedComponents/PaymentDialog.jsx";
import { useBaseData } from "../Data/BaseDataProvider.jsx";
import { useDialogs } from "../Common/DialogsProvider.jsx";

const PaymentContext = React.createContext(null);

/**
 * @returns {PaymentContext}
 */
export const usePayment = () => useContext(PaymentContext);

// eslint-disable-next-line require-jsdoc
const PaymentProvider = ({ children }) => {
  const { userInfo, requestPaymentHtml, preselectedOffer, setPreselectedOffer, refreshUserInfo } = useBaseData();
  const { pathname } = useLocation();
  const snackbar = useDialogs();

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  useEffect(() => {}, [userInfo]);

  /** */
  const pay = (id) => {
    const existOffer = userInfo && userInfo.offers.find((offer) => offer.status === "pending");

    if (existOffer && !existOffer.paid && existOffer.offerId !== id) {
      return snackbar.warn("there is already an unpaid offer");
    }

    setIsDialogOpened(true);
    requestPaymentHtml(id, "offer").then();
    refreshUserInfo();
  };

  useEffect(() => {
    if (userInfo && preselectedOffer) {
      pay(preselectedOffer);
      setPreselectedOffer(null);
    }
  }, [preselectedOffer, pathname]);

  return (
    <PaymentContext.Provider value={{ pay }}>
      {children}
      {isDialogOpened && (
        <PaymentDialog isDialogOpened={isDialogOpened} handleCloseDialog={() => setIsDialogOpened(false)} />
      )}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
